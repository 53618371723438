<template><div><h1 id="disabling-ro-firmware-protection-on-ti50" tabindex="-1"><a class="header-anchor" href="#disabling-ro-firmware-protection-on-ti50"><span>Disabling RO firmware protection on Ti50</span></a></h1>
<div class="custom-container tip"><p class="custom-container-title">TIP</p>
<p>This is only applicable to devices with a Ti50 security chip, which is typically found in devices released during or after mid-2023.</p>
</div>
<div class="custom-container tip"><p class="custom-container-title">TIP</p>
<p>If you disabled WP via CCD and reset your CCD flags with <code v-pre>ccd reset factory</code>, you can skip this step.</p>
</div>
<br>
<h2 id="step-1-verify-the-device-has-a-ti50" tabindex="-1"><a class="header-anchor" href="#step-1-verify-the-device-has-a-ti50"><span>Step 1: Verify the device has a Ti50</span></a></h2>
<ol>
<li>Open VT-2 (<code v-pre>ctrl</code> + <code v-pre>alt</code> + <code v-pre>f2</code> (right arrow)).</li>
<li>Login as <code v-pre>root</code>.</li>
<li>Run <code v-pre>gsctool -a -I | grep AllowUnverifiedRo</code>.</li>
<li>If that command has any output, your device has a Ti50. If you get no output or an error, your device either has a Cr50 or no GSC at all.</li>
</ol>
<h2 id="step-2-disable-ro-verification" tabindex="-1"><a class="header-anchor" href="#step-2-disable-ro-verification"><span>Step 2: Disable RO verification</span></a></h2>
<ol>
<li>Unlock the GSC by running <code v-pre>gsctool -a -o</code>.</li>
<li>Run <code v-pre>gsctool -a -I AllowUnverifiedRo:always</code>.</li>
<li>Press the power button when prompted.</li>
</ol>
<div class="custom-container warning"><p class="custom-container-title">WARNING</p>
<p>If your device has a Ti50 and you don't disable RO verification, flashing full rom will brick the device (can be recovered with steps listed below).</p>
</div>
<h2 id="recovering-a-device-bricked-due-to-ro-verification" tabindex="-1"><a class="header-anchor" href="#recovering-a-device-bricked-due-to-ro-verification"><span>Recovering a device bricked due to RO verification</span></a></h2>
<h3 id="chromebooks" tabindex="-1"><a class="header-anchor" href="#chromebooks"><span>Chromebooks</span></a></h3>
<ol>
<li>Press and hold the <code v-pre>Power</code> button.</li>
<li>Press the <code v-pre>Refresh</code> (arrow icon) button twice.</li>
<li>Release the <code v-pre>Power</code> button.</li>
<li>Repeat the above steps a second time.</li>
</ol>
<h3 id="chromeboxes" tabindex="-1"><a class="header-anchor" href="#chromeboxes"><span>Chromeboxes</span></a></h3>
<ol>
<li>Press and hold the <code v-pre>Power</code> button.</li>
<li>Press the recovery pinhole button twice.</li>
<li>Release the <code v-pre>Power</code> button.</li>
<li>Repeat the above steps a second time.</li>
</ol>
<h3 id="tablets" tabindex="-1"><a class="header-anchor" href="#tablets"><span>Tablets</span></a></h3>
<ol>
<li>Press and hold the <code v-pre>Power</code> button.</li>
<li>Press and hold <code v-pre>Volume Up</code> for 10+ seconds. Release and repeat it a second time.</li>
<li>Release the <code v-pre>Power</code> button.</li>
<li>Repeat the above steps a second time.</li>
</ol>
<p>This will disable RO verification for 15 minutes, allowing you to permanently disable it.</p>
</div></template>


